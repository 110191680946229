* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background:linear-gradient(to bottom right, #f37c44, #ffffff); */
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.darkText {
  color: black;
  line-height: 40px;
  margin-left: 1rem;
}

.centerDiv {
  display: flex;
  justify-content: center;
}

.subButton {
  align-items: center;
  background:linear-gradient(to right, #F04337, #FBAE40);
  background-color:#FFA503 /*this your primary color*/
}

.text {
	text-transform: uppercase;
  font-size: 38px;
	background: linear-gradient(to right, #F55209 0%, #FFA503 100%);
	-webkit-background-clip: text;
  background-clip: text;
	-webkit-text-fill-color: transparent;
}

@media (max-width: 600px) 
{
  .text {
    text-transform: uppercase;
    font-size: 28px;
    background: linear-gradient(to right, #F55209 0%, #FFA503 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.center {
  display: flex;
  justify-content: 'center';
  align-items: 'center';
}

.socials {
  font-size: 32px;
  text-align: center;
  justify-content: center;
  margin-top: 4px;
}

.socialIconColor {
  color: #F55209;
}

