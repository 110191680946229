.Intro {
  text-align: center;
  height: 20px;
}

.InfoCards {
  text-align: center;
  margin: 5rem auto;
  height: 20px;
}

.slide {
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1.0);
  transition: transform 300ms;
  opacity: 1;
}

.arrow {
  background-color: #FFF;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

.module {
  border-radius: 10;
  text-decoration-color: blueviolet;
}

.boldHeaderText {
  font-size: 48px;
  padding: 0px;
}

.divCenter {
  display: flex;
  justify-content: center;
}

.divUnderline {
  height: 10px;
  width: 50%;
  background: #F55209;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}
